<template>
  <div class="card p-shadow-6">
    <h1>Portfólio Informação do Negócio</h1>
    <div
      class="p-grid p-col-12 p-md-12"
      :style="{ position: 'relative', minHeight: '200px' }"
      v-if="classification == null"
    >
      <Loading :active.sync="loading" :isFullPage="false"></Loading>
    </div>
    <div class="p-grid p-col-12 p-md-12">
      <div class="p-col-12 p-md-6" v-if="classification !== null">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                name="marketing_name"
                v-model="classification.marketing_name"
                :disabled="true"
              />
              <label for="marketing_name">Nome Marketing</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="case_study"
                v-model="classification.case_study"
                :disabled="true"
              />
              <label for="case_study">Caso de Estudo</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="business_name"
                v-model="classification.business.name"
                :disabled="true"
              />
              <label for="business_name">Negócio</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="client"
                v-model="classification.business.client"
                :disabled="true"
              />
              <label for="client">Cliente</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="seller"
                v-model="classification.business.seller"
                :disabled="true"
              />
              <label for="seller">Vendedor</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="sellerDate"
                v-model="classification.business.sellerDate"
                :disabled="true"
              />
              <label for="sellerDate">Data da Venda</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3 p-ml-1">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="solutions">Soluções</label>
            </span>
            <span v-if="!Array.isArray(classification.solutions)" class="p-ml-1"
              >Sem Soluções</span
            >
            <Tag
              v-for="solution in classification.solutions"
              :key="solution.id"
              class="p-mr-2"
              :value="solution.name"
            />
          </div>
          <div class="p-field p-col-12 p-mt-3 p-ml-1">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
            >
              <label for="business_areas">Áreas de Negócio</label>
            </span>
            <span
              v-if="!Array.isArray(classification.business_areas)"
              class="p-ml-1"
              >Sem Áreas de Negócio</span
            >
            <Tag
              v-for="businessArea in classification.business_areas"
              :key="businessArea.id"
              class="p-mr-2"
              :value="businessArea.name"
            />
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="description_project"
                :autoResize="true"
                rows="5"
                v-model="classification.description_project"
                :disabled="true"
              />
              <label for="description_project">Descrição do Projeto</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="marketing_action"
                :autoResize="true"
                rows="5"
                v-model="classification.marketing_action"
                :disabled="true"
              />
              <label for="marketing_action">Ações de Marketing</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <Textarea
                type="text"
                name="actions_taken"
                :autoResize="true"
                rows="5"
                v-model="classification.actions_taken"
                :disabled="true"
              />
              <label for="actions_taken">Ações Efetuadas</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-mt-3">
            <span class="p-float-label">
              <InputText
                type="text"
                name="case_study"
                v-model="classification.updated_at"
                :disabled="true"
              />
              <label for="case_study">Ultima Atualizção</label>
            </span>
          </div>
          <div
            class="p-field p-col-12 p-md-6"
            v-for="file in filesList"
            :key="file.id"
          >
            <div v-if="file.mimeType == 'application/pdf'" class="pdf-viewer">
              <embed
                :src="`data:${file.mimeType};base64,${file.file}`"
                type="application/pdf"
                width="100%"
                height="300px"
              />
            </div>
            <div v-else style="text-align: center">
              <ImagePreview
                width="300"
                :src="`data:${file.mimeType};base64,${file.file}`"
                :alt="file.file_origin_name"
                preview
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-col-12 p-md-6"
        v-if="classification !== null && can('seeBusinessClassificationLogs')"
      >
        <div style="max-height: 60%">
          <DataTable
            :value="logs"
            :filters.sync="filters"
            filterDisplay="row"
            class="p-datatable-sm p-mt-3"
            :sortOrder="1"
            responsiveLayout="scroll"
            removableSort
            sortMode="single"
            :scrollable="true"
            scrollHeight="flex"
            :autoLayout="false"
            :loading="loadingLogs"
          >
            <template #empty>
              Esta classificação não tem registos de alterações
            </template>
            <template #loading> A carregar. Por favor Aguarde... </template>
            <template #header>
              <div class="table-header">Registo de Alterações</div>
            </template>
            <Column
              sortable
              field="date"
              header="Data"
              filterField="date"
              :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            >
            </Column>
            <Column
              sortable
              field="type"
              header="Tipo"
              filterField="type"
              :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            >
            </Column>
            <Column
              sortable
              field="user"
              header="Utilizador"
              filterField="user"
              :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
            >
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  type="text"
                  class="p-column-filter"
                  v-model="filterModel.value"
                  @input="filterCallback()"
                />
              </template>
            </Column>
            <Column
              sortable
              field="changes"
              header="Alterações"
              filterField="changes"
              :headerStyle="{ 'min-width': '50%', 'max-width': '50%' }"
              :filterHeaderStyle="{ 'min-width': '50%', 'max-width': '50%' }"
              :bodyStyle="{ 'min-width': '50%', 'max-width': '50%' }"
            >
              <template #body="slotProps">
                <span v-html="slotProps.data.changes"></span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  type="text"
                  class="p-column-filter"
                  v-model="filterModel.value"
                  @input="filterCallback()"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "primevue/tag";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import businessService from "../services/business.service";
import { getOnlyDate } from "../helpers/helpers";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "portfolio",
  components: {
    Loading,
    Tag,
  },
  data() {
    return {
      loading: false,
      loadingLogs: false,
      classification: null,
      filesList: [],
      logs: [],
      filters: {
        user: { value: null, matchMode: FilterMatchMode.CONTAINS },
        changes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async created() {
    this.loading = true;
    this.classification = await businessService.getClassification(
      this.$route.params.id
    );

    this.classification.business.sellerDate = getOnlyDate(
      new Date(this.classification.business.sellerDate)
    );
    this.classification.case_study =
      this.classification.case_study === 1 ? "Sim" : "Não";

    this.filesList = await businessService.getBusinessClassificationFiles(
      this.$route.params.id
    );
    this.loading = false;
    if (this.can("seeBusinessClassificationLogs")) {
      this.logs = await businessService.getClassificationLogs(
        this.$route.params.id
      );
    }
  },
  methods: {},
};
</script>
